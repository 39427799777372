import React, { useState } from "react";
import "../assets/style/layout.scss"
import WalletModal from "../pages/wallet-modal";
import { getShortAddress } from "../utils/normal";
import { useWallets } from "../context/wallet_provider";
const Logo = require("../assets/img/logo.png");


const Header: React.FC = () => {

  const [openWalletModal, setOpenWalletModal] = useState<boolean>(false)
  const { connected, address: wallet_address, disconnect } = useWallets();

  return (
    <div id="header">
      <div className="logo-part">
        <img className="header-logo" src={Logo} alt="" />
        <div className="title-network">
          <div className="network">Mainnet</div>
          <div className="title">Disperse</div>
        </div>
      </div>
      {!connected && <div className="connect-wallet-button" onClick={() => setOpenWalletModal(true)}>CONNECT WALLET</div>}
      {connected && <div className="connect-wallet-button" onClick={() => { disconnect() }}>{getShortAddress(wallet_address)}</div>}
      {openWalletModal && <WalletModal setOpenWalletModal={setOpenWalletModal} />}
    </div>
  )
}

export default Header;