import React from "react";
import Header from "./header";
import "../assets/style/layout.scss";


const Layout: React.FC<{ children: any }> = ({ children }) => {

  return (
    <div id="layout">
      <Header />
      {children}
    </div>
  );
};

export default Layout;
