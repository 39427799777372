import { useEffect, useState, useMemo } from "react";
import { Window as KeplrWindow } from "@keplr-wallet/types";
import { Wallet } from "./wallet";

export type UseKeplrResult =  | [true, boolean, Wallet[]]
    | [false, boolean, undefined];

export const useKeplr = (isKeplrConnected: boolean):UseKeplrResult =>{
    const [hasKeplr, setHasKeplr] = useState(false);
    const [addresses, setAddresses] = useState<string[]>([]);
    const [ready, setReady] = useState(false);
    const munChainId = "testmun";
    useEffect(()=>{
        const handleLoad = () =>{
            const keplr = (window as KeplrWindow)?.keplr;
            const hasKeplr = !!keplr;
            if (hasKeplr){
                console.log("keplr installed");
            }
            setHasKeplr(hasKeplr);
            if (!hasKeplr){
                setReady(false);
            }
        }
        window.addEventListener("load", handleLoad);
        return ()=>window.removeEventListener("load", handleLoad);
    }, []);
    useEffect(()=>{
        const effect = async()=>{
            if (!hasKeplr || !isKeplrConnected){
                return;
            }
            try{
                const keplr = (window as KeplrWindow).keplr;
                if (!keplr){
                    console.log("no keplr");
                    return;
                }
                await keplr.experimentalSuggestChain(
                    {
                        chainId: munChainId,
                        chainName: "mun",
                        rpc: "https://mainnet1rpc.mun.money",
                        rest: "https://mainnet1.mun.money",
                        bip44: {
                            coinType: 118
                        },
                        coinType: 118,
                        bech32Config: {
                            bech32PrefixAccAddr: "mun",
                            bech32PrefixAccPub: "munpub",
                            bech32PrefixValAddr: "munvaloper",
                            bech32PrefixValPub: "munvaloperpub",
                            bech32PrefixConsAddr: "munvalcons",
                            bech32PrefixConsPub: "munvalconspub"
                        },
                        currencies: [
                            {
                                coinDenom: "MUN",
                                coinMinimalDenom: "umun",
                                coinDecimals: 6,
                                coinGeckoId: "dig-chain"
                            }
                        ],
                        feeCurrencies: [
                            {
                                coinDenom: "MUN",
                                coinMinimalDenom: "umun",
                                coinDecimals: 6,
                                coinGeckoId: "dig-chain"
                            }
                        ],
                        stakeCurrency: {
                            coinDenom: "MUN",
                            coinMinimalDenom: "umun",
                            coinDecimals: 6,
                            coinGeckoId: "dig-chain"
                        },
                        features: []
                    }
                );

                await keplr.enable(munChainId);
                const offlineSigner = await keplr.getOfflineSignerAuto(munChainId);
                const accounts = await offlineSigner.getAccounts();
                setAddresses(accounts.map((account)=>account.address));
            }catch(exception){
                console.log("failed to conect to keplr");
            }
            setReady(true);
        };
        effect();
    },[hasKeplr, isKeplrConnected])

    const wallet = useMemo(()=>{
        if (addresses.length === 0) {
            const wallet: Wallet = {
              address: "",
              connected: false,
            };
            return [wallet];
          }

          return addresses.map((address, index) => {
            const wallet: Wallet = {
              address,
              connected: true,
            };
            return wallet;
          });
    }, [addresses])

    return hasKeplr && isKeplrConnected? [true, ready, wallet] :[false, ready, undefined];

}