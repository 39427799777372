import React from "react";
import "../assets/style/wallet-modal.scss"

import { KeplrIcon } from "../assets/icons";
import { useWallets } from "../context/wallet_provider";

const WalletModal: React.FC<{ setOpenWalletModal: any }> = ({ setOpenWalletModal }) => {
  const { selectKeplr } = useWallets();
  return (
    <div id="wallet-modal" onClick={() => setOpenWalletModal(false)}>
      <div className="wallet-modal-card">
        <div className="title">Connect a wallet to continue</div>

        <div className="wallet-item" onClick={() => {
          selectKeplr();
          setOpenWalletModal(false);
        }}>
          <div style={{ marginLeft: "20px" }}><KeplrIcon size={"2rem"}></KeplrIcon></div>
          <div className="wallet-name">Keplr Wallet</div>
        </div>
      </div>
    </div>
  )
}

export default WalletModal;