import React, { FC, ReactNode, createContext, useContext, useState, useMemo } from "react";
import { useKeplr } from "./keplr";

interface WalletContextState{
    connected: boolean;
    address: string;
    selectKeplr: ()=>void;
    disconnect: ()=>void;
}

const DEFAULT_CONTEXT = {
    connected: false,
    address: "",
} as WalletContextState;

export const WalletContext = createContext<WalletContextState>(
    DEFAULT_CONTEXT as WalletContextState
);

interface WalletProviderProps {
    children: ReactNode;
}

export const useWallets = () => useContext(WalletContext);

export const WalletProvider: FC<WalletProviderProps> = ({children}) => {
    const [isKeplrConnected, setIsKeplrConnected] = useState(false);
    const [hasKeplr, keplrIsReady, keplrWallets] = useKeplr(isKeplrConnected);
    const selectKeplr = ()=>{
        console.log('selectKeplr')
        setIsKeplrConnected(true);
    }
    const disconnect = ()=>{
        setIsKeplrConnected(false);
    }

    const value = useMemo(()=>{
        if (
            hasKeplr &&
            keplrWallets &&
            keplrWallets.length &&
            keplrWallets[0].connected
        ){
            return {
                connected: true,
                address: keplrWallets[0].address,
                selectKeplr,
                disconnect
            }
        }else{
            return {
                connected: false,
                address: "",
                selectKeplr,
                disconnect
            }
        }
    }, [ hasKeplr, isKeplrConnected, keplrIsReady, keplrWallets]);

    return (
        <WalletContext.Provider value={value}>
            {children}
        </WalletContext.Provider>
    )
}