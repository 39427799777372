import React, { useEffect, useState } from "react";
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { Window as KeplrWindow } from "@keplr-wallet/types";
import { GasPrice, coins } from "@cosmjs/stargate";
import "../assets/style/home.scss";
import { MUN_CHAIN_ID, MUN_RPC, MUN_BATCH_CONTRACT } from "../assets/data/config";
import { useWallets } from "../context/wallet_provider";

const Home: React.FC = () => {

  const { connected, address } = useWallets();

  const [addressList, setAddressList] = useState<string[]>([]);
  const [amountList, setAmountList] = useState<string[]>([]);
  const [updateState, setUpdateState] = useState<boolean>(false);

  const formatData = (content: string) => {
    if (!content) return;
    let target_address_list = [];
    let target_amount_list = [];

    let array = content.replaceAll(",", "").replaceAll(/\n/g, " ").split(" ");
    for (let i = 0; i < array.length; i += 2) {
      target_address_list.push(array[i]);
      target_amount_list.push(array[i + 1]);
    }
    setAddressList(target_address_list);
    setAmountList(target_amount_list);
    setUpdateState((value) => !value);
  };

  const get_total_number = () => {
    let number = 0;

    addressList.map((item, index) => {
      if (item !== "" && Number(amountList[index]) > 0) {
        number = number + 1;
      }
    });

    return number;
  };

  const get_total_amount = () => {
    let amount = 0;

    amountList.map((item, index) => {
      if (addressList[index] !== "" && Number(item) > 0) {
        amount = amount + Number(item);
      }
    });

    return amount;
  };

  const send = async () => {
    if (!connected) return;

    const keplr = (window as KeplrWindow)?.keplr;
    const offlineSigner = keplr?.getOfflineSigner(MUN_CHAIN_ID);
    const options = {
      prefix: 'mun',
      gasPrice: GasPrice.fromString('0.001umun')
    };
    const client = await SigningCosmWasmClient.connectWithSigner(
      MUN_RPC, offlineSigner!, options
    );

    let real_amount_list: string[] = [];
    amountList.map((item) => {
      real_amount_list.push((parseInt(item) * 1000000).toString(10));
    });

    let msg = {
      batch_transfer: {
        address_list: addressList,
        amount_list: real_amount_list
      }
    };

    try {
      const result = await client.execute(address, MUN_BATCH_CONTRACT, msg, "auto", "", coins(get_total_amount()*1000000, "umun"));
      if (result) window.alert("Successfully Transferred");
      else window.alert("Failed Transferred");
    } catch (error) {
      console.log(error);
      window.alert("Failed Transferred");
    }
  }

  useEffect(() => {
    get_total_number();
    get_total_amount();
  }, [updateState]);

  return (
    <div id="home">
      <div className="container">
        <textarea onChange={(e) => formatData(e.target.value)} className="info-textarea"></textarea>
        <div className="right-box">
          <div className="total-info">
            You will send<div className="info-text">{get_total_number()}</div>wallets<div className="info-text">{get_total_amount()}</div>mun
          </div>
          <div className="send-button" onClick={send}>Send</div>
        </div>
      </div>
    </div>
  );
};

export default Home;