import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layout";
import Home from "./pages/home";
import "./assets/style/global.scss"
import { WalletProvider } from "./context/wallet_provider";

function App() {
  return (
    <BrowserRouter>
      <WalletProvider>
        <Layout>
          <Routes>
            <Route path={"/"} element={<Home />} />
          </Routes>
        </Layout>
      </WalletProvider>
    </BrowserRouter>
  );
}

export default App;
